<template>
  <div>
    <head-top-other :title="title"></head-top-other>
    <div class="page">
      <div class="wrap">
        <div class="line-1">
          <!-- <div :class="['line-1-item', index===0? 'active':'']" @click="loginTabChange(0)">邮箱注册</div> -->
          <div
            :class="['line-1-item', index === 1 ? 'active' : '']"
            @click="loginTabChange(1)"
          >
            手机注册
          </div>
        </div>
        <el-form
          key="email"
          :model="emailForm"
          ref="emailForm"
          :rules="emailRules"
          v-if="index === 0"
        >
          <el-form-item prop="account">
            <el-input
              v-model="emailForm.account"
              key="email"
              autocomplete="off"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickname">
            <el-input
              v-model="emailForm.nickname"
              key="email"
              autocomplete="off"
              placeholder="昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="secret">
            <el-input
              type="password"
              key="email"
              v-model="emailForm.secret"
              autocomplete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <div class="protocol">
            注册即代表我已阅读并同意《游遍欧洲网客户协议》
          </div>
          <el-button class="submit" @click="onSubmit('emailForm')"
            >立即注册</el-button
          >
        </el-form>
        <el-form
          key="tel"
          :model="telForm"
          ref="telForm"
          :rules="telRules"
          v-else
        >
          <el-form-item prop="account">
            <el-input
              v-model="telForm.account"
              key="tel"
              autocomplete="off"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickname">
            <el-input
              v-model="telForm.nickname"
              key="tel"
              autocomplete="off"
              placeholder="昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="secret">
            <el-input
              type="password"
              key="tel"
              v-model="telForm.secret"
              autocomplete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <div class="protocol">
            注册即代表我已阅读并同意
            <router-link class="protocal-text" :to="{path: '/protocol/index'}" target="_blank">《游遍欧洲网客户协议》</router-link>
          </div>
          <el-button class="submit" @click="onSubmit('telForm')"
            >立即注册</el-button
          >
        </el-form>
        <div class="line-2">
          <span>已经拥有游遍欧洲网帐号了？</span>
          <router-link class="login" :to="{ path: '/login/login' }"
            >立即登录</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clientRegister } from "@/api/index";
import HeadTopOther from "@/components/HeadTopOther";
export default {
  name: "Register",
  data() {
    return {
      index: 1,
      title: "注册",
      emailForm: {
        account: "",
        nickname: "",
        secret: "",
        type: 101
      },
      emailRules: {
        account: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        secret: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      telForm: {
        account: "",
        nickname: "",
        secret: "",
        type: 101
      },
      telRules: {
        account: [
          { required: true, message: "请输入手机号码", trigger: "blur" }
        ],
        secret: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },
  components: {
    HeadTopOther
  },
  methods: {
    loginTabChange(index) {
      this.index = index;
    },
    onSubmit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          console.log("confirm", this[form]);
          clientRegister(this[form]).then(response => {
            if (response.code === 200) {
              this.$message({
                message: response.message,
                type: "success"
              });
            } else {
              this.$message({
                message: response.message || "注册失败",
                type: "error"
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  background: url(../../assets/index/icon-005.png) no-repeat;
  background-size: 100% 100%;
  padding: 260px 0 140px;
  .wrap {
    margin-left: 61.25%;
    width: 380px;
    height: 430px;
    background: rgba(255, 255, 255, 1);
    text-align: center;
    .line-1 {
      display: flex;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
      &-item {
        cursor: pointer;
        text-align: center;
        width: 100%;
        height: 62px;
        line-height: 62px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        &.active {
          background: rgba(111, 15, 149, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    :deep(.el-form) {
      padding: 30px;
      .protocol {
        margin: 0 -10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
        .protocal-text {
          text-decoration: underline;
        }
      }
    }
    .submit {
      width: 320px;
      height: 40px;
      background: rgba(111, 15, 149, 1);
      border-radius: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
      text-align: center;
      padding: 0;
      display: inline-block;
    }
    .line-2 {
      margin-top: -15px;
      padding: 0 30px 30px;
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 40px;
      .login {
        cursor: pointer;
        width: 107px;
        height: 40px;
        border: 1px solid rgba(111, 15, 149, 1);
        border-radius: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(111, 15, 149, 1);
        line-height: 40px;
        text-align: center;
        margin-left: 13px;
      }
    }
  }
}
</style>
